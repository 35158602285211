import {
  ADD_TIMER,
  GET_TIMERS,
  GET_TIMERS_ERROR,
  GET_TIMERS_SUCCESS,
  UPDATE_TIMER,
} from 'redux/constants/timer';

export const getTimersAction = (data) => {
  return { type: GET_TIMERS, payload: data };
};

export const getTimersActionSuccess = (data) => {
  return {
    type: GET_TIMERS_SUCCESS,
    payload: data,
  };
};

export const showTimerMessage = (message) => {
  return {
    type: GET_TIMERS_ERROR,
    message,
  };
};

export const addTimerAction = (data) => {
  return {
    type: ADD_TIMER,
    payload: data,
  };
};

export const updateTimerAction = (data) => {
  return {
    type: UPDATE_TIMER,
    payload: data,
  };
};
