import { API_TIMER_URL } from 'constants/ApiConstant';
import io from 'socket.io-client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addTimerAction, updateTimerAction } from 'redux/actions/timer';

export const SocketTimerContext = React.createContext();

const timerSocket = io(API_TIMER_URL, {
  reconnectionAttempts: 4,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
  timeout: 80000,
});

const TimerProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [waitTimer, setWaitTimer] = useState(false);

  useEffect(() => {
    // setTask(currentTask);
    timerSocket.on('TIMER_STARTED', (data) => {
      dispatch(addTimerAction(data.payload));
      setWaitTimer(false);
    });
    timerSocket.on('TIMER_PLAYED', (data) => {
      dispatch(updateTimerAction(data.payload));
      setWaitTimer(false);
    });
    timerSocket.on('TIMER_PAUSED', (data) => {
      dispatch(updateTimerAction(data.payload));
    });
    timerSocket.on('TIMER_STOPPED', (data) => {
      dispatch(updateTimerAction(data.payload));
    });

    return () => {
      timerSocket.off('TIMER_STARTED');
      timerSocket.off('TIMER_PLAYED');
      timerSocket.off('TIMER_PAUSED');
      timerSocket.off('TIMER_STOPPED');
    };
  }, [timerSocket]);

  return (
    <SocketTimerContext.Provider
      value={{ timerSocket, waitTimer, setWaitTimer }}
    >
      {children}
    </SocketTimerContext.Provider>
  );
};

export default TimerProvider;
