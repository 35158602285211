import {
  ADD_TIMER,
  GET_TIMERS_ERROR,
  GET_TIMERS_SUCCESS,
  UPDATE_TIMER,
} from 'redux/constants/timer';

const initialState = {
  timers: [],
  message: '',
  error: false,
};

function TimerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TIMERS_SUCCESS:
      const initialTimers = Array.from(
        new Map(action.payload.map((timer) => [timer._id, timer])).values()
      );
      return { ...state, timers: initialTimers, message: '', error: false };

    case GET_TIMERS_ERROR:
      return { ...state, timers: [], message: action.message, error: true };

    case ADD_TIMER:
      let uniqueAddTimers = Array.from(
        new Map(state.timers.map((timer) => [timer._id, timer])).values()
      );
      const existingTimer = uniqueAddTimers.find(
        (timer) => timer._id === action.payload._id
      );
      if (existingTimer) return { ...state, timers: uniqueAddTimers };
      uniqueAddTimers.unshift(action.payload);
      return {
        ...state,
        timers: uniqueAddTimers,
      };

    case UPDATE_TIMER:
      const uniqueUpdateTimers = Array.from(
        new Map(state.timers.map((timer) => [timer._id, timer])).values()
      );
      let updatedTimers = uniqueUpdateTimers.filter(
        (timer) => timer._id !== action.payload._id
      );
      updatedTimers.unshift(action.payload);
      return {
        ...state,
        timers: updatedTimers,
      };

    default:
      return state;
  }
}

export default TimerReducer;
